<template>
  <AddressesComponent />
</template>

<script>
import AddressesComponent from '../components/addresses/AddressesComponent.vue';

export default {
  name: 'Addresses',
  components: {
    AddressesComponent,
  },
};
</script>

<style scoped>

</style>
